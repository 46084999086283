import React from 'react';

import Toaster, { Props as ToasterProps } from '../components/toaster';

type TToasterContextType =
  | {
      open: (props: ToasterProps) => void;
      close: () => void;
    }
  | undefined;

type TToasterState = ToasterProps | null;

const ToasterContext = React.createContext<TToasterContextType>(undefined);

export const ToasterProvider: React.FC = ({ children }) => {
  const [toaster, setToaster] = React.useState<TToasterState>(null);

  const open = React.useCallback((props: ToasterProps) => {
    setToaster(props);
  }, []);

  const close = React.useCallback(() => {
    setToaster(null);
  }, []);

  const memoValue = React.useMemo(() => ({ open, close }), [open, close]);

  return (
    <ToasterContext.Provider value={memoValue}>
      {children}
      {toaster && <Toaster {...toaster} />}
    </ToasterContext.Provider>
  );
};
export const useToaster = () => {
  const context = React.useContext(ToasterContext);
  if (context === undefined) {
    throw new Error('useToaster must be used within a ToasterProvider');
  }
  return context;
};
