import { Headline, Text } from './styles';
import { faCheck as faCheckSolid } from '@fortawesome/pro-solid-svg-icons/faCheck';
import Icon from '../../icon';
import { InlineSpacer } from '../../spacer';
import { Spinner } from '../../spinner';
import { theme } from '../../../styles/default.theme';

export const DocumentIsProcessing: React.FC = () => {
  return (
    <>
      <Headline>
        <Icon icon={faCheckSolid} size="1x" color={theme.palette.primary.main} />
        <InlineSpacer size={8} />
        Payment confirmed
      </Headline>
      <Spinner size={82} />
      <Text fontWeight="500" color="main">
        We're still generating your Word file.
      </Text>
      <Text>
        This is normal, generating your document might take a few minutes. Feel free to close this
        window, we'll email you the download link as soon as it's ready.
      </Text>
    </>
  );
};
