// Copyright (C) 2020 UNSILO. All Rights Reserved.

import styled from 'styled-components';

type Props = {
  size?: number;
  speed?: number;
};

export const Spinner: React.FC<Props> = ({ size = 12, speed = 1 }) => (
  <StyledSpinner size={size}>
    <Circle speed={speed} />
  </StyledSpinner>
);

export default Spinner;

const StyledSpinner = styled.div<{ size: number }>`
  box-sizing: border-box;
  position: relative;
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  display: grid;
  place-items: center;
`;

const Circle = styled.div<{ speed?: number }>`
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  animation: ${({ speed }) => `rotate ${speed}s linear infinite`};
  background: white;
  border-radius: 50%;

  &:before {
    content: '';
    position: absolute;
    border-radius: 50%;
    background: linear-gradient(0deg, hsla(217, 100%, 50%, 0) 50%, hsla(217, 100%, 50%, 0.3) 100%) 0
        0,
      linear-gradient(90deg, hsla(217, 100%, 50%, 0.3) 0%, hsla(217, 100%, 50%, 0.6) 100%) 100% 0,
      linear-gradient(180deg, hsla(217, 100%, 50%, 0.6) 0%, hsla(217, 100%, 50%, 0.9) 100%) 100%
        100%,
      linear-gradient(360deg, hsla(217, 100%, 50%, 0.9) 0%, hsla(217, 100%, 50%, 1) 100%) 0 100%;
    background-repeat: no-repeat;
    background-size: 50% 50%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &:after {
    content: '';
    position: absolute;
    background: white;
    border-radius: 50%;
    top: 2px;
    bottom: 2px;
    left: 2px;
    right: 2px;
  }
`;
