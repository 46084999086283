import styled from 'styled-components';

const StyledHelperText = styled.p`
  margin-bottom: 0 !important;
  font-weight: 400;
`;

const CoreHelperText: React.FC = ({ children, ...props }) => {
  return <StyledHelperText {...props}>{children || <>&nbsp;</>}</StyledHelperText>;
};

export default CoreHelperText;
