import styled from 'styled-components';

const ModalTitle: React.FC = ({ children }) => {
  return <Title>{children}</Title>;
};

export default ModalTitle;

const Title = styled.h2`
  color: #0a235c;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 2rem;
  font-weight: normal;
  text-align: center;
`;
