import styled from 'styled-components';

export const spinnerContainer = styled.div`
  margin-top: 64px;
  width: 240px;
  margin-bottom: 32px;
  margin-left: 90px;
`;

export const header = styled.div`
  display: flex;
  align-items: center;
  color: #0a235c;
`;

export const counter = styled.div`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;
  color: #0a235c;
`;

export const spinner = styled.div`
  margin-left: 6px;
  flex-grow: 1;
`;

export const arrow = styled.div`
  cursor: pointer;
  background: #fff;
  border-radius: 0.4rem;
  height: 40px;
  font-size: 16px;
  width: 40px;
  display: grid;
  place-content: center;

  &:hover {
    background: #eef3f6;
  }
  &:active {
    background: #dde8ed;
  }
`;

type Colors = 'default' | 'grey';

const toHex = (color: Colors) => (color === 'default' ? 'inherit' : 'rgb(163,172,194)');

export const suggestion = styled.div<{ color: Colors }>`
  border: 1px solid #afb7ca;
  border-radius: 8px;
  background-color: #ffffff;
  margin-top: 8px;
  padding: 24px ${({ theme }) => theme.fontSize.regular};
  color: ${(props) => toHex(props.color)};
  box-shadow: 0px 8px 32px -16px rgba(10, 35, 92, 0.25);
  color: #0a235c;
`;

export const category = styled.div`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 8px;
`;

export const explanation = styled.div`
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 24px;
`;

export const text = styled.div`
  font-size: ${({ theme }) => theme.fontSize.regular};
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 16px;
  font-family: IBM Plex Serif;
`;

export const replacement = styled.span`
  font-weight: 600;
  font-family: IBM Plex Serif;
`;
