import { apiClient } from '../api-client';

export async function fetchSession() {
  const url = `/api/v1/live-session/`;
  const res = await apiClient.post<GetSessionIdResponse>(url);
  return res.data;
}
interface GetSessionIdResponse {
  data: {
    liveSessionId: string;
  };
}
