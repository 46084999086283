import { inspect } from '@xstate/inspect';
import ReactDOM from 'react-dom';

import App from './app';
import Providers from './providers';
import { startPreviewService } from './state/preview.machine';

if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_XSTATE) {
  inspect({ iframe: false });
}

startPreviewService();

ReactDOM.render(
  <Providers>
    <App />
  </Providers>,
  document.querySelector('#root'),
);
