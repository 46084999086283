import { apiClient } from './api-client';

export type SessionMetadata = {
  data: {
    documentInfo: {
      captured: boolean;
      documentId: string;
      hasMoreText: boolean;
      liveSessionId: string;
      originalFileName: string;
      previewText: string;
      status: 'UPLOADED' | 'SUBMITTED' | 'SUCCESS' | 'FAILED';
      url?: string;
    };
    liveSessionId: string;
  };
};

export const getSession = async (sessionId: string): Promise<SessionMetadata> => {
  return await apiClient.get<SessionMetadata>(`/api/v1/live-session/${sessionId}/metadata`);
};
