export function getTokens(text: string, start: number, end: number, replacement: string) {
  return [getPreviousToken(text, start), replacement, getNextToken(text, end)];
}

export function getPreviousToken(text: string, index: number) {
  return getToken('last', text.slice(0, Math.max(0, index)));
}

export function getNextToken(text: string, index: number) {
  return getToken('first', text.slice(index));
}

export function getToken(location: 'first' | 'last', text: string) {
  if (!text.includes(' ')) {
    return text;
  }

  const tokens = text.trim().split(' ');

  if (location === 'first') {
    const prefix = text.startsWith(' ') ? ' ' : '';
    return prefix + tokens[0];
  } else {
    const suffix = text.endsWith(' ') ? ' ' : '';
    return tokens[tokens.length - 1] + suffix;
  }
}
