import ContentEditable from 'react-contenteditable';
import styled from 'styled-components';

export const left = styled.div`
  grid-row-end: span 3;
`;

export const centerTop = styled.div``;

export const centerMain = styled.div`
  grid-row-end: span 3;
  display: flex;
  overflow: auto;
`;

export const rightTop = styled.div`
  display: flex;
`;

export const rightMain = styled.div``;

export const rightBottom = styled.div``;

export const container = styled.div`
  display: flex;
  grid-row-end: span 3;
  grid-column-end: span 3;
`;

export const content = styled.div`
  padding-top: 32px;
  display: flex;
  flex: 1;
`;

export const header = styled.div`
  padding: 36px;
`;

export const contentEditable = styled(ContentEditable)`
  min-height: 32px;
  opacity: 0.4;
  color: #0a235c;
  font-family: 'IBM Plex Serif', 'Segoe UI', Tahoma, sans-serif;
  font-size: 20px;
  letter-spacing: 0;
  padding: 4px;
  width: calc(50% - 32px);
  margin-left: 32px;
`;

export const segments = styled.div`
  padding: 4px;
  width: calc(50% - 32px);
`;

export const segment = styled.span`
  opacity: 0.6;
  color: #0055ff;
  font-family: 'IBM Plex Serif', 'Segoe UI', Tahoma, sans-serif;
  font-size: 20px;
  letter-spacing: 0;
  border: 1px solid #0055ff;
  border-radius: 8px;
  padding: 2px;
  margin-right: 4px;
`;
