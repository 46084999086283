import styled from 'styled-components';

export type Props = {
  body?: React.ReactNode;
};

const StyledToaster = styled.div`
  position: absolute;
  padding: 0.5rem 1rem;
  background-color: #051e39;
  color: #fff;
  font-size: 1.2rem;
  top: 84px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 3px;
`;

const Toaster: React.FC<Props> = ({ body, children }) => {
  return <StyledToaster>{body || children}</StyledToaster>;
};

export default Toaster;
