import styled from 'styled-components';

const getHeight = ({ axis, size }: SpacerProps) => {
  return axis === 'horizontal' ? 1 : size;
};
const getWidth = ({ axis, size }: SpacerProps) => {
  return axis === 'vertical' ? 1 : size;
};

type SpacerProps = {
  axis?: 'horizontal' | 'vertical';
  size: number;
};
/**
 * Spacer component to add spacing between any two elements.
 * From https://www.joshwcomeau.com/react/modern-spacer-gif/#the-code
 */
export const Spacer = styled.span<SpacerProps>`
  display: block;
  width: ${getWidth}px;
  min-width: ${getWidth}px;
  height: ${getHeight}px;
  min-height: ${getHeight}px;
`;

export const InlineSpacer = styled(Spacer)`
  display: inline-block;
`;
