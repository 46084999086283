import { forwardRef } from 'react';
import styled from 'styled-components';

import CoreHelperText from './core-helper-text';
import CoreLabel from './core-label';
import type { FieldBase } from './types';

const Label = styled(CoreLabel)`
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
`;

const HelperText = styled(CoreHelperText)`
  color: ${({ theme }) => theme.palette.error.main};
  font-size: 0.75rem;
  margin-left: 0.5rem;
`;

const StyledCheckbox = styled.input`
  margin-right: 0.5rem;
`;

type Props = FieldBase & {
  id?: string;
};

const Checkbox = forwardRef<any, Props>(({ label, id, className, errors, ...props }, reference) => {
  return (
    <Label className={className}>
      <StyledCheckbox id={id || props.name} {...props} type="checkbox" ref={reference} />
      {label}
      <HelperText>{errors[props.name]?.message}</HelperText>
    </Label>
  );
});

export default Checkbox;
