import { cloneElement, forwardRef, ReactElement } from 'react';
import styled from 'styled-components';

import CoreHelperText from './core-helper-text';
import CoreLabel from './core-label';
import type { FieldBase } from './types';

type Props = FieldBase & {
  type?: 'text' | 'number';
  className?: string;
  Component?: ReactElement;
};

type ClassNameConsumerProps = {
  className?: string;
  children: (className?: string) => React.ReactElement;
};

const Input = forwardRef<any, Props>(
  ({ Component, label, id, errors, fullWidth, className, ...props }, reference) => {
    const error = errors[props.name];
    return (
      <Label className={className}>
        <span>{label}</span>
        <StyledInput error={error?.message} fullWidth={fullWidth}>
          {(className) => {
            return Component ? (
              cloneElement(Component, { className, ref: reference })
            ) : (
              <input
                className={className}
                id={id || props.name}
                type="text"
                {...props}
                ref={reference}
              />
            );
          }}
        </StyledInput>
        <HelperText>{error?.message}</HelperText>
      </Label>
    );
  },
);

export default Input;

const Label = styled(CoreLabel)`
  color: #0a235c;
  > span {
    display: block;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
  }
`;
const HelperText = styled(CoreHelperText)`
  color: ${({ theme }) => theme.palette.error.main};
`;

const ClassNameConsumer = ({ className, children }: ClassNameConsumerProps) => children(className);

const StyledInput = styled(ClassNameConsumer)<{ fullWidth?: boolean; error?: boolean }>`
  font-size: 1rem;
  border-radius: 0.5rem;
  background-color: #ffffff;
  box-shadow: 0px 0px 1px rgba(10, 35, 92, 0.5), 0px 4px 16px -8px rgba(10, 35, 92, 0.25);
  width: 100%;
  line-height: 1.5rem;
  outline: none;
  border: 1px solid ${({ error, theme }) => (error ? theme.palette.error.main : 'transparent')};
  padding: 0.75rem;
  &:focus,
  &.${'StripeElement--focus'} {
    border-color: #0060ff;
  }
`;
