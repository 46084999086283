import { apiClient } from './api-client';

type UploadDocumentToService = {
  sessionId: string;
  documentId: string;
  hubbleId: string;
  previewText: string;
  hasMoreText: boolean;
};

export async function uploadDocumentToService(event: {
  document: File;
  sessionId: string;
}): Promise<UploadDocumentToService> {
  const { document, sessionId } = event;
  if (!document) {
    throw new Error('missing file for upload');
  }
  if (!sessionId) {
    throw new Error('missing sessionId for upload');
  }
  const formData = new FormData();
  formData.append('file', document);

  const res = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/api/v1/live-session/${sessionId}/preview`,
    {
      body: formData,
      method: 'POST',
    },
  );

  const { data } = await res.json();

  return { ...data, sessionId };
}
type TriggerProcessingArgs = {
  sessionId: string;
  documentId: string;
};

type TriggerProcessingResult = {
  data: {
    apiResponseStatus: string;
    status: number;
  };
};
export async function triggerProcessing({ sessionId, documentId }: TriggerProcessingArgs) {
  return await apiClient.post<TriggerProcessingResult>(
    `/api/v1/live-session/${sessionId}/${documentId}/submit`,
  );
}
