import { FallbackProps } from 'react-error-boundary';
import styled from 'styled-components';
import { SUPPORT_MAIL } from '../util/constants';
import Button from './button';
import { SadPaperpalLogo } from './logo.component';

export const ErrorDisplay: React.FC<FallbackProps> = ({ resetErrorBoundary }) => (
  <Container>
    <Center>
      <SadPaperpalLogo width={100} height={142} />
    </Center>
    <Heading>Oh no! Something went wrong…</Heading>
    <Text>
      It is most likely a temporary issue. Please give us a moment or two and try again. <br />
      But if the issue persists, please{' '}
      <a href={`mailto:${SUPPORT_MAIL}`}>reach out to us right here</a>.
    </Text>
    <Center>
      <Button onClick={resetErrorBoundary} variant="outline">
        Try again (🤞)
      </Button>
    </Center>
  </Container>
);

const Container = styled.div`
  display: grid;
  width: 100%;
  place-content: center;
  row-gap: 1rem;
  padding-top: 120px;
  text-align: center;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
`;

const Heading = styled.h2`
  font-weight: 400;
  font-size: 1.5rem;
`;

const Text = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-bottom: 2rem;
`;
