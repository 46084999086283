import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
//should be arrow-up-from-bracket but we don't have version 6 yet
import { faUpload } from '@fortawesome/pro-light-svg-icons/faUpload';

import { useDocument } from '../context/document-context';
import Button from './button';
import Icon from './icon';

const UploadButton: React.FC = () => {
  const { dropZone, send, isUploading } = useDocument();

  return isUploading ? (
    <Button
      onClick={() => send({ type: 'UPLOAD_CANCEL' })}
      startAdornment={<Icon icon={faTimes} spacer={32} />}
    >
      Cancel
    </Button>
  ) : (
    <div {...dropZone.getRootProps()}>
      <input {...dropZone.getInputProps()} />
      <Button startAdornment={<Icon icon={faUpload} spacer={32} />}>Upload Word doc</Button>
    </div>
  );
};

export default UploadButton;
