import styled from 'styled-components';

/**
 * Spacer components used to simply add spacing between elements.
 * 'spacing' prop is the amount of space in pixels
 * if 'direction' prop is omitted, space is added in both directions
 * for reasons why this is a good idea, see https://mxstbr.com/thoughts/margin/
 */
export const spacer = styled.div<{ spacing: number; direction?: 'vertical' | 'horizontal' }>`
  width: ${({ spacing, direction }) => (direction !== 'vertical' ? `${spacing}px` : 'initial')};
  height: ${({ spacing, direction }) => (direction !== 'horizontal' ? `${spacing}px` : 'initial')};
`;
