import { useEffect, useState } from 'react';
import { Elements as StripeElements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { useDocument } from '../../../context/document-context';

const StripeHandler: React.FC = ({ children }) => {
  const [stripeReference, setStripeReference] = useState<
    Stripe | PromiseLike<Stripe | null> | null
  >(null);
  const { state } = useDocument();

  useEffect(() => {
    if (state.context.stripePublishableKey && !stripeReference) {
      const stripePromise = loadStripe(state.context.stripePublishableKey as string);
      setStripeReference(stripePromise);
    }
  }, [state.context.stripePublishableKey, stripeReference]);

  return <StripeElements stripe={stripeReference}>{children}</StripeElements>;
};

export default StripeHandler;
