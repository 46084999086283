import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';

import { useDocument } from '../context/document-context';
import { useResetEditor } from '../hooks/use-reset-editor';
import Button from './button';
import Icon from './icon';

const ClearButton: React.FC = () => {
  const { reset } = useResetEditor({ withConfirm: true });
  const { state } = useDocument();

  //@ts-expect-error
  const shouldShow = state.value.uploaded?.documentProcessing || null;

  return (
    shouldShow && (
      <Button onClick={reset} startAdornment={<Icon icon={faTimes} spacer={32} />}>
        Clear
      </Button>
    )
  );
};

export default ClearButton;
