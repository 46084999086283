import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { DocumentProvider } from './context/document-context';
import { ModalProvider } from './context/modal-context';
import { ToasterProvider } from './context/toaster-context';
import { theme } from './styles/default.theme';

const queryClient = new QueryClient();

const Providers: React.FC = ({ children }) => {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <DocumentProvider>
            <ToasterProvider>
              <ModalProvider>{children}</ModalProvider>
            </ToasterProvider>
          </DocumentProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

export default Providers;
