import styled from 'styled-components';

const StyledLabel = styled.label`
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
`;

type Props = {
  htmlFor?: string;
  className?: string;
};

const CoreLabel: React.FC<Props> = (props) => {
  return <StyledLabel {...props} />;
};

export default CoreLabel;
