export const Terms = () => (
  <>
    <h2>Terms of Use</h2>
    <h3>General</h3>
    <ul>
      <li>
        This website, paperpal.com, and all its sub domains ("Site") are owned and operated by
        Cactus Communications Group. Any references to "we," "us," "our," "I" refer to Cactus
        Communications Group. The Services are wholly offered and provided by Cactus Communications.
      </li>
      <li>
        The term you or your or client or customer refers to the customer who avails or intends to
        avail the services, whether chargeable or not offered on this website subject to these Terms
        of Use (ToU)
      </li>
      <li>
        The term source documents/information or "source files" refers to the articles, manuscripts,
        papers, academic papers, theses, dissertation documents, pictures, your or your
        Laboratory&rsquo;s or institutions research papers you choose to upload, data relating to
        your preferred Journals and your choice of published papers, etc. and also includes
        reference of the DOI and PMCID which are submitted to us for rendering the final product.
        The term Final product refers to the end product / report/ suggested readings which will be
        recommended based on the source files provided by you.&nbsp;
      </li>
      <li>
        Your continued use and access of this Site indicates that you have read, understood, and
        accepted the ToU. If you do not want to be bound by the ToU, you agree to immediately
        discontinue your use of this Site.
      </li>
      <li>
        In addition to these ToU, your use of our website is also governed by our privacy policy,
        cookie policy which are available at here. The Privacy policy governs our use of your
        personal information. It sets out the types of personal information we collect, the reasons
        we collect it for, how we use it, where we may pass it on to any third parties, in what
        circumstances, and for what reasons, and any other relevant information relating to our use
        and/or processing of your personal information and your rights in relation to your personal
        information.&nbsp;
      </li>
      <li>
        By accessing and using our website you consent to our processing of your personal
        information in accordance with our privacy policy, and you consent to our use of cookies in
        accordance with our cookies policy.
      </li>
    </ul>
    <h3>Purpose of This Site and the Content Therein</h3>
    <ul>
      <li>
        All the information presented on this Site ("Content") is for informational purposes only
        and does not create a business or professional services relationship between us. A service
        relationship may be established when you place a request for the Services and the same is
        accepted by us.
      </li>
      <li>
        We reserve the right to undertake all necessary steps to ensure that the security, safety,
        and integrity of our systems as well as its users' interests are and remain,
        well&ndash;protected. Towards this end, we may take various steps to verify and confirm the
        authenticity, enforceability, and validity of service orders/ requests placed by you.
      </li>
    </ul>
    <h3>Fees and Invoicing</h3>
    <ul>
      <li>
        We will issue you a valid invoice in respect of each chargeable service. We will notify you
        as soon as the deliverables/ recommendations are ready for download or available for your
        reference. You give us authority to contact on the personal details provided by you, once
        our deliverables/ recommendations are ready. Except as specified herein or in the Order Form
        or on the Site (i) fees are based on the Services requested / purchased irrespective of
        actual usage/ download of the final product, (ii) payment obligations are non-cancellable.
      </li>
      <li>
        In case you&rsquo;ve opted for our services, depending upon the type of service, you will be
        required to make the payment either prior to availing the service i.e. pre-paid or within
        (7) days from the invoice date.
      </li>
      <li>
        You hereby grant us rights to use your information to follow up on all or any outstanding
        invoices payable to us.
      </li>
      <li>
        We may send out communication (written or verbal) to update you on completion of payment
        cycle for the outstanding invoices.
      </li>
      <li>
        In case your invoices are outstanding /overdue for more than 30 days, we may block your
        account and restrict you from accessing our services until the full payment is made for the
        outstanding invoice.
      </li>
      <li>
        There may be multiple payment gateways mentioned on our website through which you can make
        payment for the services. However we are not liable for any damage that you may suffer on
        the account of an error or default on the part of any payment gateway/s listed on our
        website.
      </li>
      <li>
        Payment shall be made: (a) in full without set-off, counterclaim or withholding of any kind
        (save where and to the extent that this cannot by law be excluded); (b) in the currency
        mentioned in the order confirmation (quotation); and (c) any foreign exchange conversion
        charges or any other charged levied by the bank or payment gateway are to be borne by the
        customer.
      </li>
      <li>
        You are responsible for providing complete and accurate billing and contact information to
        us and notifying us of any changes to such information.
      </li>
      <li>
        Billing Dispute: If you have any disputes with regards to billing, such disputes shall be
        governed by the provisions of this clause:&nbsp;&nbsp;
      </li>
      <ul>
        <li>
          You agree that all Invoices raised by us are valid and payable except to the extent of a
          valid billing dispute you raise with us in accordance with the provisions of this clause.
          Except to the extent of the disputed amount, you agree that the invoices would be valid
          and payable. Further you undertake to pay such undisputed amount in accordance with the
          provisions of "Fees and Invoicing" clause.
        </li>
        <li>
          To raise a valid billing dispute, you must (i) make a good faith request by writing to us
          on support@researcher.life_______________ to investigate the specific charges or invoice,
          providing at the same time specific evidence which demonstrates that a particular charge
          or invoice is incorrect; and (ii) make any such request to us within 3 days from date of
          the relevant invoice.&nbsp;&nbsp;
        </li>
        <li>
          On receiving good faith dispute request from you, we will conduct investigations which are
          reasonably necessary and appropriate to substantiate the matter.&nbsp; At the end of these
          investigations, you will pay the outstanding amount, if any, with interest at the rate of
          12 % p.a. from the date of which the amount would have been due to the date of actual
          payment.
        </li>
      </ul>
    </ul>
    <h3>Intellectual Property</h3>
    <ul>
      <li>
        The Content presented on this Site (including but not limited to text, design, software,
        graphics, audio, video, HTML code, and data) is protected by copyright law, patent law,
        trademark law, and other applicable intellectual property laws and is the exclusive property
        of us. You agree to follow all instructions on this Site limiting the way you may use the
        Content.
      </li>
      <li>
        We reserve all rights to this Site and its Content. This Content may not be copied,
        reproduced, republished, uploaded, posted, transmitted, or distributed in any way
        whatsoever, without our prior written consent. You may use the Content for your limited
        personal and non-commercial purposes in accordance with applicable law governing
        intellectual property rights. You may download and print one copy of any Content expressly
        specified as available for download solely for your personal, non-commercial use. Provided
        however, that you hereby agree not to modify the Content so downloaded, in any way
        whatsoever and that you shall not alter any copyright, trademark, and other proprietary
        notices or symbols attached/affixed to such Content. Any other use or modification of the
        Content without our prior written authorization is expressly prohibited.
      </li>
      <li>
        Trademarks, logos, and service marks displayed on this Site are our sole and exclusive
        property. Nothing contained in this Site shall be construed as conferring any license or
        right to any copyright, trademark, logo, service mark, or other proprietary interest of us
        or any third party.
      </li>
    </ul>
    <h3>Customers Warranties&nbsp;</h3>
    <ul>
      <li>
        You affirm that you own the copyrights of the source files you provide us for availing the
        services or you affirm that you have the necessary authority to provide the source files to
        us for availing our services and in either case you authorize us to use the source files for
        rendering the services envisaged by you from us.
      </li>
      <li>
        You agree to indemnify us in case any third party claims infringement of Copyright in the
        source files provided to us by you or any other third party claims resulting out of breach
        of these ToU by you.
      </li>
      <li>
        You hereby agree that access to certain areas of this Site may be available only to
        registered users. To become a registered user, you may be required to provide us certain
        information. You hereby represent and warrant that all information supplied to the Site is
        true, complete, and accurate.
      </li>
    </ul>
    <h3>Use of the Site</h3>
    <ul>
      <li>
        You hereby agree not to use contact information provided on the Site for any unauthorized
        purposes, including but not limited to marketing. You shall not use any hardware or software
        intended to damage or interfere with the proper working of the Site or to surreptitiously
        intercept any system, data, personal information, or Content on the Site. You agree not to
        interrupt or attempt to interrupt the operation of the Site in any manner whatsoever. We
        hereby reserve the right, in our sole discretion, to limit or terminate your access to or
        use of the Site at any time without notice to you.
      </li>
      <li>
        You agree and undertake to use the Site appropriately. By way of example, and not as a
        limitation, you agree and undertake that when using the Site, you will not:
      </li>
      <ul>
        <li>
          Upload source files that infringes any patent, trademark, copyright, or other
          proprietary/intellectual property rights of another person or Post information that
          belongs to another person and to which you do not have any rights to;
        </li>
        <li>
          Upload source files that contain viruses, Trojan horses, worms, keystroke loggers,
          spyware, adware or corrupted files, or any other computer code, files or programs designed
          to interrupt, destroy, harm, or limit the functionality of the Site or another user's
          computer, computer system/network/database or user&rsquo;s data or software;
        </li>
        <li>
          Upload source files that threatens the unity, integrity, defence, security, sovereignty,
          friendly relations with foreign states, or public order or causes incitement to the
          commission of any cognisable offence or prevents investigation of any offense or is
          insulting any other nation;
        </li>
        <li>Post information that harms minors</li>
        <li>stalk, or otherwise violate the legal rights of other users</li>
        <li>
          Post information that infringes any patent, trademark, copyright, or other
          proprietary/intellectual property rights of another person or Post information that
          belongs to another person and to which you do not have any rights to
        </li>
        <li>
          Post files that contain viruses, Trojan horses, worms, keystroke loggers, spyware, adware
          or corrupted files, or any other computer code, files or programs designed to interrupt,
          destroy, harm, or limit the functionality of the Site or another user's computer, computer
          system/network/database or user&rsquo;s data or software;
        </li>
        <li>
          conduct or forward surveys, contests, pyramid schemes, or chain letters on the Site;
        </li>
        <li>
          download any file Posted by another user that you know, or reasonably should know, cannot
          be legally distributed in such a manner;
        </li>
        <li>
          Post any information that deceives or misleads the addressee about the origin of such
          messages or impersonates another person or communicates any information that is grossly
          offensive or menacing in nature;
        </li>
        <li>violate any applicable laws or regulations for the time being in force; or</li>
        <li>
          make the Site available over a network where it could be used by multiple devices or
          multiple users at the same time.
        </li>
      </ul>
      <li>
        You shall be solely responsible for complying with the laws of the country from which you
        are accessing this Site and you agree that you will not access or use the information on
        this Site in violation of such laws. In addition, you may not use this Site:
      </li>
      <ul>
        <li>
          In a way that breaches any applicable local, national, or international law or regulation;
        </li>
        <li>
          In a way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or
          effect; or
        </li>
        <li>
          To transmit, or procure the sending of, any unsolicited or unauthorized advertising or
          promotional material or any other similar form of solicitation (spam) or any material that
          you are not authorized to use, disclose, distribute, or share.
        </li>
      </ul>
      <li>
        Any usage of the unique ID and password created and provided to you shall be solely for your
        personal use. You shall not share this with anyone and you would be responsible for any
        unauthorized access or usage of the same.
      </li>
      <li>Regulatory Powers</li>
      <li>You hereby acknowledge and agree that we reserve the right to:</li>
      <ul>
        <li>
          limit, deny, or restrict the use or access to the Site in entirety or certain portions of
          the Site to specific users, including yourself, at our sole discretion;
        </li>
        <li>reject a user from registering on the Site without assigning any reason thereof;</li>
        <li>
          suspend/cancel/deactivate any user&rsquo;s account/registration for any reason that we
          shall deem fit, or
        </li>
        <li>delete, edit, or move any Content Posted on the Site for any reason whatsoever.</li>
        <li>
          Use your name, logo or any other trademark for marketing and promotional activities if you
          have availed any service(s) from our website. Such usage shall be in a manner that does
          not derogate your rights in your trademarks, names and logos by us.&nbsp;&nbsp;
        </li>
        <li>
          Use your source files uploaded on this platform to analyze, enhance or develop and build
          services, tools and systems for customers benefits
        </li>
      </ul>
    </ul>
    <h3>Confidentiality&nbsp;</h3>
    <ul>
      <li>
        We shall maintain complete confidentiality of source files. All our vendors are
        contractually obliged to maintain strict confidentiality of user data and have agreed not to
        disclose such confidential information to unauthorized persons. Access to the user data is
        limited to the person who is in charge or required to work on the document. We also assure
        compliance with applicable laws concerning the protection of personal information and
        promise to handle Customer's personal information with great care.
      </li>
      <li>
        The information collected from the users by us shall be held and maintained in accordance
        with our "Privacy Policy".
      </li>
    </ul>
    <h3>Breach of T&amp;Cs</h3>
    <ul>
      <li>
        When a breach of these T&amp;Cs has occurred, we may take all or any of the following
        actions or any such action as it deems appropriate, including but not limited to the
        following:
      </li>
      <ul>
        <li>Immediate, temporary, or permanent withdrawal of your right to use the Site;</li>
        <li>Issuance of a warning to you;</li>
        <li>
          Initiation of legal proceedings against you for reimbursement of all resulting costs and
          expenses suffered by us on an indemnity basis (including but not limited to reasonable
          administrative and legal costs); and
        </li>
        <li>
          Disclosure of such information to law enforcement authorities as is reasonably necessary.
        </li>
      </ul>
      <li>
        We hereby expressly disclaim all liability for any and all actions taken by us in response
        to a breach of the ToU committed by you.
      </li>
      <li>Changes to the Site and These Terms</li>
      <li>
        We may alter or modify the features of the Site with respect to different user(s), or change
        any of the features or introduce new features on the Site without prior notice to any user.
      </li>
      <li>
        We reserve the right, at our sole discretion, to change, modify, add, or remove any portion
        of these ToU, in whole or in part, at any time, temporarily or permanently, without notice
        and without liability, by posting revised terms on the Site. You hereby agree that it is
        your responsibility to check periodically for any changes made to these ToU. Your continued
        use of the Site after any changes to these ToU signifies your acceptance of the updated ToU.
      </li>
    </ul>
    <h3>Age Restrictions</h3>
    <ul>
      <li>Our website is not intended for use by individuals under the age of 18 years.</li>
      <li>
        We do not knowingly or intentionally process the personal information of any individual
        under the age of 18.
      </li>
      <li>
        You must not use our website, purchase or attempt to purchase any of our products or
        services, or submit any personal information to us, if you are under the age of 18.
      </li>
      <li>Disclaimers and Limitation of Liability</li>
      <li>
        While we will use reasonable efforts to provide reliable Content through this Site, we do
        not warrant that this Site is free of inaccuracies, errors, and/or omissions. This Site may
        contain certain historical information, which is provided for your reference only. We
        reserve the right to modify the Content presented on this Site at any time, without notice
        to you.
      </li>
      <li>
        The Site may contain links to other websites ("Embedded Sites"). The Embedded Sites are not
        under our control and we are not responsible for the contents of any Embedded Site,
        including without limitation any link contained in an Embedded Site, or any changes or
        updates to an Embedded Site. We are not responsible for any form of transmission,
        whatsoever, received by you from any Embedded Site. We are providing these links or allowing
        users to provide you these links only as a convenience, and the inclusion of any link does
        not imply endorsement by us of the Embedded Sites or of any information contained in the
        Embedded Site or any association with the operators or owners of the Embedded Site
      </li>
      <li>
        You hereby expressly acknowledge that internet transmissions are never completely private or
        secure. You understand that any message or information sent by you to us or Posted on the
        Site may be read or intercepted by others unless there is a special notice that a particular
        message (for example, credit card information) is encrypted (sent in code). It is expressly
        clarified that we do not bear any additional responsibility towards you on account of your
        sending a message to us.
      </li>
      <li>
        For the avoidance of doubt, it is clarified that except as otherwise expressly provided in
        these ToU, we make no representations, guarantees, or warranties, written or oral, express
        or implied, to the user or to any other person or entity regarding the services, the Content
        on the Site, any hardware, or software.
      </li>
    </ul>
    <h3>Grievance Redressal</h3>
    <ul>
      <li>
        Should you have any grievance or complaints in relation to your use of the Site, please
        contact us, at privacy@cactusglobal.com, who shall respond to your grievance within 14 days.
      </li>
      <li>Governing Law/Dispute Resolution</li>
      <li>
        If any dispute arises between you and us in connection with your usage of our website or any
        of our services shall be solely resolved by reference to arbitration. The place of
        arbitration shall be India. The arbitration proceedings shall be in English language.
      </li>
      <li>
        These terms and conditions are governed by and shall be construed in accordance with the
        laws of India and any dispute shall, subject to the arbitration clause specified above,
        exclusively be subject to the jurisdiction of the appropriate Courts situated at Mumbai.
      </li>
    </ul>
  </>
);
