import { useRef } from 'react';

import BuyArea from '../../components/buy-area';
import PaymentModal from '../../components/modals/payment-modal/payment-modal';
import { useDocument } from '../../context/document-context';
import { useModal } from '../../hooks/use-modal';
import { Preview } from './components/preview';
import SuggestionsBrowser from './components/suggestions-browser';
import { WordLimitFooter } from './components/word-limit-footer';
import * as s from './editor-workspace.styles';

const EditorWorkspace = () => {
  const { Modal, openModal } = useModal();
  const { state, send } = useDocument();

  /**
   * use ref for it not to jump around because of re-render.
   * this is not the best salutation, but for now i think it gets the job done
   * but it is necessary because the aside is position: fixed;
   * I know this is hacky as fu*k
   */
  const asideRightHight = useRef(
    window.innerHeight -
      36 /** padding top from editor */ -
      96 /** header height */ -
      120 / 2 /** half footer height */ +
      5,
  );

  const onStartProcessing = () => {
    send('INITIATE_DOCUMENT_PROCESSING');
    send('INITIATE_PAYMENT');
    openModal();
  };

  return (
    <>
      <s.asideLeft />
      <s.editor>
        {state.context.preview && state.context.sessionId && (
          <Preview text={state.context.preview} sessionId={state.context.sessionId} />
        )}
        <WordLimitFooter
          onBtnClick={onStartProcessing}
          hasMoreText={Boolean(state.context.hasMoreText)}
        />
      </s.editor>

      <s.asideRight>
        <s.asideRightInner style={{ height: asideRightHight.current }}>
          <SuggestionsBrowser />
          <BuyArea isPaymentCaptured={state.hasTag('paymentCaptured')} />
        </s.asideRightInner>
      </s.asideRight>
      <Modal>
        <PaymentModal />
      </Modal>
    </>
  );
};

export default EditorWorkspace;
