import { Headline, Text, Check } from './styles';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { SUPPORT_MAIL } from '../../../util/constants';
import Button from '../../button';
import Icon from '../../icon';
import { useResetEditor } from '../../../hooks/use-reset-editor';

export const DocumentFailed: React.FC = () => {
  const { reset } = useResetEditor({ withConfirm: false });

  return (
    <>
      <Headline>Document failed to generate</Headline>
      <Check bg="error">
        <Icon icon={faTimes} size="3x" />
      </Check>
      <Text>
        We could not generate your Word document for some reason. We will not charge your card, or
        if it was already charged we will automatically refund the purchase.
      </Text>
      <Text>
        Please try again, and if the problem persists, reach out to us at{' '}
        <a href={`mailto:${SUPPORT_MAIL}`}>{SUPPORT_MAIL}</a>.
      </Text>
      <Button onClick={reset} variant="solid" color="primary" fullWidth>
        Try again (🤞)
      </Button>
    </>
  );
};
