import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

type Props = {
  spacer?: number;
};

const Icon: React.FC<FontAwesomeIconProps & Props> = ({ spacer, ...props }) => {
  return spacer ? (
    <IconContainer spacer={spacer}>
      <FontAwesomeIcon {...props} scale="100%" />
    </IconContainer>
  ) : (
    <FontAwesomeIcon fixedWidth {...props} />
  );
};

export default Icon;

const IconContainer = styled.div<{ spacer?: number }>`
  height: ${({ spacer }) => spacer}px;
  width: ${({ spacer }) => spacer}px;
  display: grid;
  place-content: center;
`;
